@font-face {
    font-family: "Akrobat Regular";
    src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Regular.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Regular.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Regular.ttf) format("truetype")
}

@font-face {
    font-family: "Akrobat Bold";
    src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Bold.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Bold.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Bold.ttf) format("truetype")
}

@font-face {
    font-family: "Akrobat Light";
    src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Light.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Light.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Light.ttf) format("truetype")
}

@font-face {
    font-family: "Akrobat Black";
    src: url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Black.eot?) format("eot"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/MiConv.com__Akrobat-Black.woff) format("woff"), url(https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDERGO/TYPOS/Akrobat-Black.ttf) format("truetype")
}

@font-face {
    font-family:"myriad-pro";
    src:url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1b1b1e/00000000000000000001709e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
    }

@font-face {
    font-family: 'myriad-pro-bold';
    src: url('../public/fonts/myriad_pro_semibold.otf') format('opentype');
  }

@font-face {
    font-family: 'Hyperspace Race Italic';
    src:
        url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.eot?') format('eot'),
        url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.woff') format('woff'),
        url('https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/IMAGEN%20THUNDER%20MOTOS/TYPOS/Hyper/hyperspacerace-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



:root {
    --gris: #3c3c3b;
    --amarillo: #FFD400;
    --naranja: #EA5B0C;
    --rojo: #c21632;
    --verde: #A6CE39;
}

body {
    margin: 0;
    font-family: "myriad-pro", sans-serif;
    font-weight: 400;
    font-style: normal;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/

/***** Header *****/

.header_container {
    position: relative;
    top: 0;
    z-index: 999;
    background-color: #fff;
}

.sticky_header {
    position: sticky;
    top: 0;
    animation: sticky_header .5s ease forwards;
    animation-delay: .3s;
    opacity: 0;
    transform: translateY(-100px);
}

@keyframes sticky_header {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

header div#hs_menu_wrapper_my_menu ul {
    display: flex;
    list-style: none;
    align-items: center;
}

header div#hs_menu_wrapper_my_menu ul li.hs-menu-item a {
    color: #000;
    text-decoration: none;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 14px;
    transition: all .3s ease;
    font-family: sans-serif;
}

header div#hs_menu_wrapper_my_menu ul li.hs-menu-item.hs-menu-depth-1.active-branch a {
    color: var(--verde) !important;
    transition: all .3s ease;
}


/***** Main Menu ****/

#main_menu {
    position: absolute;
    display: none;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 4;
    opacity: 0;
    animation: fadeUp 0.5s ease forwards;
}

#main_menu.active {
    display: flex;
}

.main_menu_column.active .column_title {
    border-left: 1px solid var(--verde);
    border-right: 1px solid var(--verde);
    border-top: 1px solid var(--verde);
    color: var(--verde);
}

.column_title {
    text-align: center;
    /*background-color: #fff;*/
    color: #c3c3c3;
    cursor: pointer;
    position: absolute;
    z-index: 5;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_menu_column.active .column_title {
    display: flex;
    border-radius: 4px 4px 0 0;
}

.main_menu_column.prev .column_title,
.main_menu_column.next .column_title {
    display: flex;
}

.main_menu_column.active .column_title:before {
    content: "";
    position: absolute;
    width: 100%;
    left: -101%;
    bottom: 0;
    border-width: .8px;
    border-style: solid;
}

.main_menu_column.active .column_title:after {
    content: "";
    position: absolute;
    width: 100%;
    right: -101%;
    bottom: 0;
    border-width: .8px;
    border-style: solid;
}


.models_column {
    margin-top: 60px;
    background-color: #fff;
    display: none;
}

.main_menu_column.active .models_column {
    display: flex;
    opacity: 0;
    animation: fadeUp 0.5s ease forwards;
    flex-wrap: wrap;
    width: 100vw;
    overflow-x: scroll;
}

.horizontal_flex .main_menu_column.active .models_column {
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.horizontal_flex .main_menu_column.active .models_column .model {
    min-width: 240px;
    scroll-snap-align: start;
    width: 100%;
}

.main_menu_column.active .model {
    width: 240px;
    margin: 1rem 1rem 0 1rem;
    padding: 1rem;
    text-align: center;
    opacity: 0;
    animation: fadeUp 0.5s ease forwards;
}

.model .model_title {
    margin: 0;
}

.model .model_title img {
    width: 50%;
    height: auto;
}

.model .model_img {
    object-fit: contain;
}

.model a.model_url {
    text-decoration: none;
    color: #000;
    display: block;
}

.model .model_price {
    display: block;
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.burger-menu {
    display: none;
    cursor: pointer;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
}

#back_btn {
    display: none;
    height: 24px;
    width: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    top: 0;
    left: 2rem;
    position: absolute;
    z-index: 5;
    padding: 0;
}

#back_btn span.back_icon_btn {
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    height: 8px;
    margin: auto;
    position: absolute;
    transform: rotate(45deg) translate(-50%, -50%);
    width: 8px;
    left: calc(50% - 2px);
    top: calc(50% + 2px);
}


@media (max-width: 768px) {
    .main_menu_column.active .models_column {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
    }

    .main_menu_column.active .model {
        scroll-snap-align: start;
    }

    #main_menu {
        padding-top: 18px;
    }

    span#hs_cos_wrapper_my_menu.active {
        top: 77px;
    }

    #back_btn.btn {
        display: grid;
        place-content: center;
    }

    header div#hs_menu_wrapper_my_menu ul {
        display: none;
    }

    span#hs_cos_wrapper_my_menu {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 4;
    }

    span#hs_cos_wrapper_my_menu.active ul {
        display: block;
        background-color: #fff;
        padding: 2rem;
        margin: 0;
    }

    span#hs_cos_wrapper_my_menu.active ul li.hs-menu-item a {
        font-size: 1.2rem;
        display: inline-block;
    }

    .burger-menu {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 20px;
    }

    .column_title {
        display: flex;
    }

    .header {
        display: block !important;
        width: 100vw !important;
        height: fit-content !important;
    }

    .header .header-iconos {
        display: flex;
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

@media (max-width: 620px) {
    .column_title {
        display: none;
    }

    .main_menu_column.active .column_title,
    .main_menu_column.next .column_title,
    .main_menu_column.prev .column_title {
        width: 33.33% !important;
        display: flex;
        opacity: 0;
        animation: fadeUp 0.5s ease forwards;
    }

    .main_menu_column.active .column_title {
        left: 33.33% !important;
    }

    .main_menu_column.next .column_title {
        left: 66.66% !important;
    }

    .main_menu_column.prev .column_title {
        left: 0% !important;
    }
}




/***** Catalog *****/


.spacing_txt {
    font-size: 12px;
    letter-spacing: 4px;
}



/***** Carrousel Logo *****/

.carousel-container {
    width: 100%;
    overflow: hidden;
    background-color: rgba(182, 189, 193, 0.5);
    padding-top: 36px;
    padding-bottom: 36px;
}

.carousel {
    display: flex;
    transition: transform 0.5s ease;
    /*user-select: none;*/
}

.logo {
    flex: 0 0 200px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.info-form .logo{
    width: 90vw;
    max-width: 500px;
}

.logo img {
    max-width: 100%;
}

/* Breakpoint para tablet */
@media (max-width: 768px) {
    .carousel {
        width: auto !important;
    }

    .logo {
        flex: 0 0 calc(33.33% - 10px);
    }
}

/* Breakpoint para móvil */
@media (max-width: 480px) {
    .logo {
        flex: 0 0 calc(50% - 10px);
    }
}



/***** Footer *****/

footer {
    background-color: #000;
    padding: 4rem 4rem 1rem 4rem;
    color: #fff;
    font-size: 14px;
}

footer>div {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 3rem;
}

footer .span3 {
    flex: 1 0 200px;
}

span#hs_cos_wrapper_left_menu ul,
span#hs_cos_wrapper_right_menu ul,
span#hs_cos_wrapper_contact_info ul {
    list-style: none;
    padding: 0;
}

span#hs_cos_wrapper_left_menu ul li a,
span#hs_cos_wrapper_right_menu ul li a {
    color: #fff;
    text-decoration: none;
}

div#hs_cos_wrapper_copy {
    font-size: 12px;
    letter-spacing: 1px;
}

footer .flex_container h3 {
    font-size: 1.5rem;
}

footer li {
    margin: 10px 0;
}

footer .lightgreen{
    color: var(--verde);
}


/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/
/*************************/

.header {
    background-color: white;
    display: flex;
    height: 90px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 20;
    box-shadow: 3px 3px 5px 6px #ccc;
}

.header .header_logo {
    /*height: fit-content;*/
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 20px;
}

.header .header-iconos {
    display: flex;
}

.header-iconos a {
    margin: .5rem;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--verde);
}

.header .header-iconos div svg {
    color: var(--verde);
    margin: auto;
}

.header-iconos .carrito {
    position: relative;
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(15deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-15deg); }
    100% { transform: rotate(0deg); }
  }

  @keyframes vertical-shaking {
    0% { transform: translateY(0) }
    25% { transform: translateY(10px) }
    50% { transform: translateY(-10px) }
    100% { transform: translateY(0) }
  }

.header-iconos #carrito-icon.move svg path {
   background-color: red;
   animation: tilt-shaking 0.5s infinite;
}

.header-iconos #carrito-cant.move{
    animation: vertical-shaking 0.5s;
}


.header-iconos .carrito #carrito-cant {
    position: absolute;
    top: -10px;
    right: -10px;
    left: auto;
    bottom: auto;
    margin: auto;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: var(--verde);
    color: white;
    text-align-last: center;
}

#user-menu {
    position: absolute;
    top: 90px;
    background-color: white;
    color: black;
    cursor: auto;
    box-shadow: 0 0 5px black;
    width: 150px;
    transition: transform .2s;
}

#user-menu.oculto {
    transform: translateY(-50%) scaleY(0);
    overflow: hidden;
}

#user-menu ul {
    padding: 10px;
    margin: none;
}

#user-menu li {
    cursor: pointer;
    list-style: none;
    margin-top: 10px;
}

.cont-body {
    margin-top: 120px;
}

.cont-galeria {}

.cont-galeria .cat-titulo {
    color: #3c3c3b;
    color: var(--gris);
    font-size: 25px;
    font-weight: 700;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
}

.galeria {
    display: flex;
    flex-wrap: wrap;
    place-content: space-between;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.cont-galeria .sub-familias {
    width: fit-content;
    flex-wrap: wrap;
    display: flex;
    margin: auto;
}

.cont-galeria .sub-familias button {
    cursor: pointer;
    text-align: center;
    margin: 0.5rem 1rem;
    border: solid 1px white;
    line-height: 1;
    min-height: calc(40px - 1rem);
    min-width: calc(150px - 1.6rem);
    padding: 0.8rem 2rem;
    display: grid;
    place-content: center;
    color: white;
    background-color: var(--verde);
    font-size: 18px;
    text-transform: uppercase;
}


.cont-galeria .sub-familias button.active {
    background-color: darkgray;
}


.pages-galeria {}

.pages-galeria .pagination {
    display: flex;
    list-style: none;
    margin: auto;
    width: fit-content;
}

.pages-galeria .pagination .page-item {
    margin: 10px;
    color: white;
    background-color: var(--verde);
    cursor: pointer;
    padding: 4px 10px;
}

.galerias {
    display: grid;
}

.galerias #MOTOS {
    order: 1;
}

.galerias #ACCESORIOS {
    order: 2;
}

.galeria {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    place-content: space-between;
    justify-content: flex-start;
}


.galeria .item_product {
    width: 33.3333%;
}

.galeria .producto {
    padding: 1rem;
    transition: transform .2s;
}

.galeria .producto .cont-info {
    height: 100%;
}

.galeria .producto .image {
    aspect-ratio: 16 / 9;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: solid 2px rgba(0, 0, 0, 0.1);
    display: flex;
    position: relative;
}

.galeria .producto .image .etiqueta {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 15px;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
}

.galeria .producto .image .etiqueta.nuevo {
    background-color: black;
}

.galeria .producto .image .etiqueta.oferta {
    background-color: var(--verde);
}

.galeria .producto .image .etiqueta.agotado {
    background-color: darkgray;
    color: #000;
}

.galeria .producto .cont-buttons {
    visibility: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    place-content: center;
    ;
}

.galeria .producto:hover .cont-buttons {
    visibility: visible;
}

.galeria .producto:hover .etiqueta {
    visibility:hidden;
}

.galeria .producto .cont-buttons a {
    cursor: pointer;
    text-align: center;
    margin: 0.5rem 1rem;
    padding: 5px 15px;
    border: solid 1px white;
    line-height: 1;
    min-height: calc(40px - 1rem);
    min-width: calc(150px - 1.6rem);
    padding: .5rem .8rem;
    display: grid;
    place-content: center;
}

.galeria .producto .cont-buttons a:first-child {
    color: white;
    background-color: var(--verde);
    display: none;
}

.galeria .producto .cont-buttons a:last-child {
    background-color: white;
    color: var(--verde);
}


.galeria .producto .texto {
    height: 60%;
    display: grid;
}

.galeria .producto .texto .tipo {
    text-transform: uppercase;
    color: var(--verde);
    margin-bottom: 0;
    font-size: .8rem;
}

.galeria .producto .texto>div {
    display: flex;
    font-weight: bold;
}

.galeria .producto .nombre {
    color: black;
    font-family: "myriad-pro";
    margin-top: 20px !important;
    margin-bottom: 0 !important;
    margin-right: auto;
}

.galeria .producto .precio {
    font-size: 18px;
    color: black;
    font-family: "myriad-pro";
    /*width: fit-content;*/
    margin-left: 0;
    margin-top: auto !important;
    margin-bottom: 0 !important;
}

.cont-detalle {
    margin: auto;
    width: fit-content;
    display: flex;
}

.cont-detalle img {
    border: solid 1px rgba(100, 100, 100, 0.4);
    width: 500px;
    max-width: 100vw;
}

.cont-detalle .con-info {
    padding-top: 40px;
    padding-left: 40px;
}

.cont-detalle .con-info .gris{
    color: darkgrey;
    font-family: sans-serif;
}

.cont-detalle .con-info .nombre {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0px;
    font-family: 'myriad-pro-bold';
}

.cont-detalle .con-info .precio {
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
    font-family: 'myriad-pro-bold';
}

.cont-detalle .con-info .otras-caracteristicas {
    color: darkgrey;
}

.cont-detalle .con-info button {
    cursor: pointer;
    text-align: center;
    margin: 2.5rem 1rem;
    margin-left: 0;
    border: solid 1px white;
    line-height: 1;
    min-height: calc(40px - 1rem);
    min-width: calc(150px - 1.6rem);
    padding: 0.8rem 2rem;
    display: grid;
    place-content: center;
    color: white;
    background-color: var(--verde);
    font-size: 18px;
}

.cont-branding {
    display: flex;
    margin: auto;
    width: fit-content;
    max-width: 100vw;
}

.cont-branding img {
    margin-top: auto;
    margin-bottom: auto;
    width: 20%;
}

.cont-form {
    margin-bottom: 40px;
    background-color: #f8f9fa;
    padding-top: 40px;
    padding-bottom: 40px;
}

.cont-form>p {
    color: var(--gris);
    font-size: 30px;
    font-weight: 700;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

form {
    max-width: 600px;
    margin: auto;
}


form .cont-button {
    display: flex;
}

form button {
    padding: 5px 15px;
    border: solid 1px white;
    color: white;
    margin-right: 20px;
    background-color: var(--verde);
    font-size: 26px;
}

form button.enabled {
    background-color: var(--verde);
}

form button.disabled {
    background-color: var(--gris);
    cursor: not-allowed
}

.lista-errores-form{
    display: flex;
}

.lista-errores-form ul{
    margin: auto;
    list-style: none;
}

.lista-errores-form ul li{
    color: var(--rojo);
    font-weight: bold;
    font-family: "Akrobat Bold";
}

.productos-select .producto .image {
    height: 80px;
    width: 80px;
    background-size: cover;
    background-position: center;
}


.productos-select .producto .cont-info {
    display: flex;
    align-items: center;
}

.productos-select .producto .cont-info .texto {
    display: flex;
    align-items: center;
}

.productos-select .producto .cont-info .texto .precio {
    margin-left: 15px;
    margin-right: 15px;
}

.productos-select .producto .cont-info .texto .total {
    margin-left: 15px;
}

.cont-carrito {
    width: 100%;
    max-width: 900px;
    margin: auto;
}

.cont-carrito .total{
    width: fit-content;
    margin-left: auto;
    font-weight: bold;
    font-family: "Akrobat Black";
    font-size: 25px;
    margin-right: 100px;
}

.no-compras{
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.productoCarrito .image {
    height: auto;
    width: 80px;
    background-size: cover;
    background-position: center;
}

.productoCarrito .cont-info {
    display: flex;
    align-items: center;
}

.productos-select .cont-info .texto {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: "Akrobat Black";
}


.productos-select .cont-info .texto .nombre{
    width: inherit;
}


.productos-select .cont-info .cantidad {
    display: flex;
}

.productos-select .cont-info .cantidad span {
    margin-right: 5px;
}

.productos-select .cont-info .precio {
    display: flex;
    margin-left: 5px;
}


.productos-select .cont-info .texto .separador {
    height: 1px;
    background: black;
    margin-left: 20px;
    margin-right: 20px;
    width: inherit;
}

.productos-select .cont-info .cerrar {
    font-size: 25px;
    font-weight: bold;
    margin-left: 15px;
    cursor: pointer;
    color: red;
}

.productos-select .cont-info .cant-buttons {
    display: flex;
    padding: 5px;
}

.productos-select .cont-info .cant-buttons div {
    padding: 5px;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.productos-select .cont-info .cant-buttons div:first-child {
    background-color: var(--rojo);
}

.productos-select .cont-info .cant-buttons div:last-child {
    background-color: var(--verde);
    margin-left: 5px;
}

form fieldset {
    border: none;
    max-width: 400px;
    margin: auto;
    display: flex;
}

.info-form {
    display: flex;
    width: fit-content;
    margin: auto;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;
}

.info-form .header_logo {
    width: 200px;
    max-width: 90vw;
}


/* Formularios */


form {
    margin: 4rem auto;
    font-family: "Akrobat Regular" !important;
}

form fieldset {
    max-width: 640px;
}

form fieldset input {
    margin-right: 6px;
}

form fieldset:not(.cont-terminos)>div {
    width: 48%;
    margin: auto;
}

form fieldset select {
    width: 100%;
}

form button {
    background-color: darkgray;
    border: none;
    padding: 10px 22px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    margin: auto auto auto 2rem;
}

form input:not(.checkbox),
form select {
    padding: 0;
    width: 100% !important;
    outline: none;
    line-height: 18px;
    border-radius: 0;
    border: 1px solid;
    min-height: 48px;
}



form label {
    margin: 1rem;
    display: inline-block;
    font-size: 15px;
}

form ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

form textarea input {
    width: calc(100% - 0) !important;
    min-height: 140px;
}

form .cont-terminos p {
    font-size: 10px;
}

form fieldset .aceptar-terminos {
    display: flex;
}


/*****************************/

@media (min-width: 900px) {
    .galeria .producto:hover .image {
        background-color: var(--verde);
    }
}

@media (max-width: 900px) {

    .galeria .item_product {
       width: 50%;
    }

    .galeria .producto .cont-info{
        height: 300px;
    }

    .galeria .producto .cont-buttons {
        margin-top: auto;
        visibility: visible;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .galeria .producto .cont-buttons a {
        margin: 0;
    }

    .galeria .producto .texto>div {
        display: block;
    }

    .cont-galeria .sub-familias{
        width: 100vw;
    }

    .cont-galeria .sub-familias button{
        font-size: 12px;
    }

    .galeria .producto .cont-buttons a:last-child {
        color: white;
        background-color: var(--verde);
    }

    .cont-detalle{
        display: block;
    }

    .mis-compras .lista-compras form input{
        width: 90vw !important;
        min-width: 0 !important;
    }
    .mis-compras .lista-compras form{
        display: block !important;
    }

}

.cont-pago-popup .button-pago {
    padding: 10px 20px;
    background-color: var(--naranja);
    color: white;
    /* width: -moz-fit-content;*/
    /*width: fit-content;*/
    border-radius: 15px;
    text-transform: uppercase;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 30px;
    width: fit-content;
}

.cont-pago-popup .button-pago a {
    text-decoration: none;
    color: white;
}

.mis-compras {
    margin-top: 120px;
    min-height: 90vh;
}

.mis-compras .lista-compras {
    display: block;
    max-width: 800px;
    margin: auto;
}

.mis-compras .lista-compras .titulo {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    font-family: "Akrobat Regular" !important;
}

.mis-compras .lista-compras .compra {
    margin: 15px;
    border: 1px solid green;
    padding: 10px 40px;
}

.mis-compras .lista-compras form {
    display: flex;
}

.mis-compras .lista-compras form fieldset {
    margin: 0;
}

.mis-compras .lista-compras form input {
    min-width: 400px;
}

.mis-compras .lista-compras form button {
    margin: 0;
    margin-left: 10px;
}

.error-formulario {
    background-color: red;
    border-radius: 20px;
    height: 40px;
    color: white;
    max-width: 400px;
    font-family: 'Akrobat Bold';
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    display: flex;
    margin: auto;
}

.error-formulario p {
    margin: auto;
}